import web_hosting_background from '../Assets/web-hosting-background.png';
import HostingTemplate from '../Components/HostingTemplate';
import '../App.css';

const WebHosting = () => {
    const fallingShapes = [

    ];
    const title = 'Web Hosting';
    const background = { image: `url(${web_hosting_background})` };
    const subtitle = 'Why Host Your Website Like Everyone Else? Stand Out with Our Web Hosting Services';
    const buttons = [{ text: 'Start Hosting Right', href: '#products', type: 'filled' }];
    const introText = '';

    const products = [
        {
            name: 'Starter',
            price: '1.00',
            type: 'web-hosting',
            image: 'https://www.freeiconspng.com/uploads/website-icon-5.png',  // Replace with actual URL of website-related image
            specs: [
                { disk: '10GB Disk' },
                { domains: '1 Domain' },
                { ssl: 'SSL Included' },
                { databases: '1 Database' },
                { network: 'Fair Use Network' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/website-hosting/starter',
        },
        {
            name: 'Basic',
            price: '3.99',
            type: 'web-hosting',
            image: 'https://www.freeiconspng.com/uploads/website-icon-5.png',  // Replace with actual URL of website-related image
            specs: [
                { disk: '25GB Disk' },
                { domains: '5 Domains' },
                { ssl: 'SSL Included' },
                { databases: '5 Databases' },
                { network: 'Fair Use Network' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/website-hosting/basic',
        },
        {
            name: 'Pro',
            price: '7.99',
            type: 'web-hosting',
            image: 'https://www.freeiconspng.com/uploads/website-icon-5.png',  // Replace with actual URL of website-related image
            specs: [
                { disk: '50GB Disk' },
                { domains: '10 Domains' },
                { ssl: 'SSL Included' },
                { databases: '10 Databases' },
                { network: 'Fair Use Network' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/website-hosting/pro',
        },
        {
            name: 'Premium',
            price: '11.99',
            type: 'web-hosting',
            image: 'https://www.freeiconspng.com/uploads/website-icon-5.png',  // Replace with actual URL of website-related image
            specs: [
                { disk: '75GB Disk' },
                { domains: '25 Domains' },
                { ssl: 'SSL Included' },
                { databases: '25 Databases' },
                { network: 'Fair Use Network' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/website-hosting/premium',
        },
        {
            name: 'Enterprise',
            price: '19.99',
            type: 'web-hosting',
            image: 'https://www.freeiconspng.com/uploads/website-icon-5.png',  // Replace with actual URL of website-related image
            specs: [
                { disk: '100GB Disk' },
                { domains: 'Unlimited Domains' },
                { ssl: 'SSL Included' },
                { databases: 'Unlimited Databases' },
                { network: 'Fair Use Network' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/website-hosting/enterprise',
        }
    ];



    return (
        <HostingTemplate
            background={background}
            fallingShapes={fallingShapes}
            title={title}
            subtitle={subtitle}
            buttons={buttons}
            products={products}
            introText={introText}
        />
    );
};

export default WebHosting;
