import vps_background from '../Assets/vps-background.png';
import HostingTemplate from '../Components/HostingTemplate';
import '../App.css';

const VPS = () => {
    const fallingShapes = [

    ];
    const title = 'VPS Hosting';
    const background = { image: `url(${vps_background})` };
    const subtitle = 'Supercharge Your Cloud Hosting Experience with Our VPS Hosting Services';
    const buttons = [{ text: 'Unlock Your Potential', href: '#products', type: 'filled' }];
    const introText = '';

    const products = [
        {
            name: 'Pico',
            price: '4.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '2 vCores',
                memory: '4GB',
                disk: '20GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/pico',
        },
        {
            name: 'Nano',
            price: '6.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '3 vCores',
                memory: '6GB',
                disk: '40GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',

            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/nano',
        },
        {
            name: 'Micro',
            price: '8.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '4 vCores',
                memory: '8GB',
                disk: '60GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/micro',
        },
        {
            name: 'Boost',
            price: '16.99',
            type: 'vps-hosting',
            best_seller: true,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '6 vCores',
                memory: '16GB',
                disk: '80GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/boost',
        },
        {
            name: 'Pro',
            price: '24.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '8 vCores',
                memory: '24GB',
                disk: '100GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/pro',
        },
        {
            name: 'Elite',
            price: '32.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '10 vCores',
                memory: '32GB',
                disk: '120GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/elite',
        },
        {
            name: 'Quantum',
            price: '52.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '12 vCores',
                memory: '48GB',
                disk: '160GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/quantum',
        },
        {
            name: 'Infinity',
            price: '72.99',
            type: 'vps-hosting',
            best_seller: false,
            image: 'https://www.freeiconspng.com/thumbs/icloud-logo/icloud-icon-social-transparent-icona-cloud-apple-libero-drive-ico-cloudzat-logo-png-image-14.png',  // Replace with actual URL of website-related image
            specs: {
                cpu: '16 vCores',
                memory: '64GB',
                disk: '200GB',
                network: 'Fair Usage @ 1Gbps',
                ddos_protection: 'Cosmic Guard DDoS Protection',
            },
            buy_now: 'https://billing.scarcehost.co.uk/store/vps-hosting/infinity',
        }
    ];




    return (
        <HostingTemplate
            background={background}
            fallingShapes={fallingShapes}
            title={title}
            subtitle={subtitle}
            buttons={buttons}
            products={products}
            introText={introText}
        />
    );
};

export default VPS;
