
import vps_background from '../Assets/hero-two.png';
import HostingTemplate from '../Components/HostingTemplate';
import '../App.css';

const DiscordBot = () => {
    const fallingShapes = [
        <img src="https://i.imgur.com/mF2ONxp.png" alt="Revolver" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="Python" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="Thompson" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="MP5" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="AK47" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="M249" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="big-grin" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="Python" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="Thompson" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="MP5" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="AK47" style={{ width: '50px', height: '50px' }} />,
        <img src="https://i.imgur.com/mF2ONxp.png" alt="M249" style={{ width: '50px', height: '50px' }} />,
    ];
    const title = 'Bot Hosting';
    const background = { image: `url(${vps_background})` };
    const subtitle = 'Host Your Discord Bot With A Few Clicks!';
    const buttons = [{ text: 'Start Today!', href: '#products', type: 'filled' }];
    const introText = '';

    const products = [
        {
            name: 'Budget',
            price: '1.99',
            type: 'rust-hosting',
            image: 'https://i.imgur.com/mF2ONxp.png',
            specs: [
                { cpu: '25% CPU' },
                { ram: '512MB RAM' },
                { storage: '4GB Storage' },
                { database: '1 MySQL Database' },
                { backup: '1 Backup' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/discord-bot-hosting/budget',
        },
        {
            name: 'Performance',
            price: '2.99',
            type: 'rust-hosting',
            image: 'https://i.imgur.com/mF2ONxp.png',
            specs: [
                { cpu: '50% CPU' },
                { ram: '1GB RAM' },
                { storage: '8GB NVMe SSD' },
                { database: '2 MySQL Database' },
                { backup: '2 Backup' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/discord-bot-hosting/performance',
        },
        {
            name: 'Performance+',
            price: '4.99',
            best_seller: true,
            type: 'rust-hosting',
            image: 'https://i.imgur.com/mF2ONxp.png',
            specs: [
                { cpu: '100% CPU' },
                { ram: '2GB RAM' },
                { storage: '16GB NVMe SSD' },
                { database: '3 MySQL Databases' },
                { backup: '2 Backups' },
            ],
            buy_now: 'https://billing.scarcehost.co.uk/store/discord-bot-hosting/performance-1',
        },
        
    ];


    return (
        <HostingTemplate
            background={background}
            fallingShapes={fallingShapes}
            title={title}
            subtitle={subtitle}
            buttons={buttons}
            products={products}
            introText={introText}
        />
    );
};

export default DiscordBot;
