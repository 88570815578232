import React, { useEffect, useRef } from 'react';
import HomeHero from "../Components/HomeHero";
import '../App.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faServer,
    faHeadset,
    faClock,
    faMemory,
    faMicrochip,
    faFloppyDisk,
    faDatabase,
    faVault,
    faNetworkWired,
    faShield,
    faShieldAlt
} from '@fortawesome/free-solid-svg-icons';

const Home = () => {
    const featureRefs = useRef([]);
    const planRefs = useRef([]);

    useEffect(() => {
        const featureObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('slide-in');
                    }
                });
            },
            { threshold: 0.1 }
        );

        featureRefs.current.forEach((ref) => {
            if (ref) {
                featureObserver.observe(ref);
            }
        });

        return () => {
            featureRefs.current.forEach((ref) => {
                if (ref) {
                    featureObserver.unobserve(ref);
                }
            });
        };
    }, []);

    useEffect(() => {
        const planObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('show');
                    }
                });
            },
            { threshold: 0.1 }
        );

        planRefs.current.forEach((ref) => {
            if (ref) {
                planObserver.observe(ref);
            }
        });

        return () => {
            planRefs.current.forEach((ref) => {
                if (ref) {
                    planObserver.unobserve(ref);
                }
            });
        };
    }, []);

    return (
        <div className={'App'}>
            <HomeHero />
            <div className={'feature-container'}>
                <div
                    className={'product-features'}
                    style={{ '--delay': '0s' }}
                    ref={(el) => featureRefs.current[0] = el}
                >
                    <h1 className={'feature-title txt-primary'}>Uptime</h1>
                    <FontAwesomeIcon icon={faClock} size="5x" className={'feature-icon txt-primary anim-pulse'} />
                    <p className={'feature-text txt-primary'}>
                        We take great pride in the reliability of our services, consistently prioritizing maximum uptime. Our unwavering commitment is to maintain 100% uptime, with the only exceptions occurring during necessary infrastructure updates to enhance our service quality.
                    </p>
                </div>
                <div
                    className={'product-features'}
                    style={{ '--delay': '0.3s' }}
                    ref={(el) => featureRefs.current[1] = el}
                >
                    <h1 className={'feature-title txt-primary'}>Customer Support</h1>
                    <FontAwesomeIcon icon={faHeadset} size="5x" className={'feature-icon txt-primary anim-pulse icon-pulse'} />
                    <p className={'feature-text txt-primary'}>
                        Our expert team is readily available to assist you with any inquiries and ensure a seamless start to your experience. You can submit a support ticket at any time, and we'll be here to help.
                    </p>
                </div>
                <div
                    className={'product-features'}
                    style={{ '--delay': '1.5s' }}
                    ref={(el) => featureRefs.current[2] = el}
                >
                    <h1 className={'feature-title txt-primary'}>Security</h1>
                    <FontAwesomeIcon icon={faServer} size="5x" className={'feature-icon txt-primary anim-pulse'} />
                    <p className={'feature-text txt-primary'}>
                        We understand the importance of security in the digital age. Our platform is designed with the latest security protocols to ensure the protection of your data and privacy.
                    </p>
                </div>
            </div>
            <div className={'featured-plans'} id={'featured'}>
                <h1 className={'txt-primary'}>Featured Plans</h1>
                <div className={'plan-container'}>
                    <div
                        className={'plan-card fade-up'}
                        ref={(el) => planRefs.current[0] = el}
                    >
                        <h2 className={'plan-title txt-primary'}><span className={'txt-secondary'}>Rust Hosting</span><br/>Thompson</h2>
                        <p className={'plan-price txt-primary'}>£15.50<span className={'txt-secondary'}>/month</span></p>
                        <ul className={'plan-features txt-primary'}>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faMicrochip} size="1x" className={'txt-secondary'}/><li>1.25 Cores</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faMemory} size="1x" className={'txt-secondary'}/><li>12GB RAM</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faFloppyDisk} size="1x" className={'txt-secondary'}/><li>30GB Disk</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faDatabase} size="1x" className={'txt-secondary'}/><li>2 MySql Databases</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faVault} size="1x" className={'txt-secondary'}/><li>2 Backups</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faServer} size="1x" className={'txt-secondary'}/><li>3 Allocations</li></span>
                        </ul>
                        <button className={'btn-primary'} onClick={() => { window.location = ('https://billing.scarcehost.co.uk/store/game-servers/thompson')}}>Select Plan</button>
                    </div>
                    <div
                        className={'plan-card fade-up'}
                        ref={(el) => planRefs.current[1] = el}
                    >
                        <h2 className={'plan-title txt-primary'}><span className={'txt-secondary'}>Minecraft Hosting</span><br/>Adventurer</h2>
                        <p className={'plan-price txt-primary'}>£6.00<span className={'txt-secondary'}>/month</span></p>
                        <ul className={'plan-features txt-primary'}>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faMicrochip} size="1x" className={'txt-secondary'}/><li>2 Core</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faMemory} size="1x" className={'txt-secondary'}/><li>6GB RAM</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faFloppyDisk} size="1x" className={'txt-secondary'}/><li>8GB Disk</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faDatabase} size="1x" className={'txt-secondary'}/><li>2 MySql Database</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faVault} size="1x" className={'txt-secondary'}/><li>2 Backup</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faServer} size="1x" className={'txt-secondary'}/><li>2 Allocation</li></span>
                        </ul>
                        <button className={'btn-primary'} onClick={() => { window.location = ('https://billing.scarcehost.co.uk/store/minecraft-servers/adventurer')}}>Select Plan</button>
                    </div>
                    <div
                        className={'plan-card fade-up'}
                        ref={(el) => planRefs.current[2] = el}
                    >
                        <h2 className={'plan-title txt-primary'}><span className={'txt-secondary'}>VPS Hosting</span><br/>Boost</h2>
                        <p className={'plan-price txt-primary'}>£16.99<span className={'txt-secondary'}>/month</span></p>
                        <ul className={'plan-features txt-primary'}>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faMicrochip} size="1x" className={'txt-secondary'}/><li>6 Cores (Xeon Platinum 8173M)</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faMemory} size="1x" className={'txt-secondary'}/><li>16GB RAM</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faFloppyDisk} size="1x" className={'txt-secondary'}/><li>60GB Disk</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faNetworkWired} size="1x" className={'txt-secondary'}/><li>Unmetered @ 1Gbps</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faHeadset} size="1x" className={'txt-secondary'}/><li>Basic Support</li></span>
                            <span className={'inline-item'}> <FontAwesomeIcon icon={faShieldAlt} size="1x" className={'txt-secondary'}/><li>Cosmic Guard DDoS Protection</li></span>
                        </ul>
                        <button className={'btn-primary'} onClick={() => { window.location = ('https://billing.scarcehost.co.uk/store/vps/boost')}}>Select Plan</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
