import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faDiscord, faXTwitter} from '@fortawesome/free-brands-svg-icons'
import { faX } from '@fortawesome/free-solid-svg-icons'

const About = () => {
    return (
        <div className="about-us">
            <h1>About Us</h1>
            <p>Welcome to ScarceHost Limited, where we combine top-tier technology with a friendly touch. We’re here to make your digital journey smooth and enjoyable.</p>

            <h1>Our Dedicated Staff Team</h1>
            <div className="staff-container">
                <div className="staff-member">
                    <img src="/featherfall.png" alt="featherfall-avatar" />
                    <h3>Featherfall</h3>
                    <p>Managing Director</p>

                    <div className="social-links">
                        <a href="https://x.com/Featherfal96090" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} size="2x" />
                        </a>
                        <a href="https://discord.gg/92Zfh2bf2V" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faDiscord} size="2x" />
                        </a>
                    </div>

                </div>
            </div>
            <h1>Our Story</h1>
            <p>
                In 2024, ScarceHost underwent a major upgrade under new leadership. With fresh investment in cutting-edge hardware, advanced networking, and a modern front-end infrastructure, we’re now equipped to handle all your hosting needs with the latest tech.
            </p>

            <h1>What We Do</h1>
            <p>
                We offer reliable web hosting and IT solutions that are designed to keep your online presence running smoothly. Our updated infrastructure ensures that your website or application operates efficiently and effectively.
            </p>

            <h1>Our Goals</h1>

            <p>
                <strong>Simplify Technology</strong>: We’re dedicated to making complex technology easy to use. Think of us as your tech translators, turning jargon into straightforward solutions.<br /><br />
                <strong>Stay Ahead of Trends</strong> We aim to keep you ahead of the curve with the latest innovations. We invest in future-proof technology so you don’t have to.<br /><br />
                <strong>Provide Friendly Support</strong>: Our support team is here to help, offering expertise with a dash of humor. We believe that tech support should be helpful and approachable.<br /><br />
                <strong>Build Lasting Relationships</strong>: We’re committed to growing with you. Whether you’re a startup or an established business, we’re here to support your long-term success.<br /><br />
                <strong>Why Choose Us?</strong>: When you choose ScarceHost, you’re opting for a hosting experience that is both dependable and pleasant. We’re here to ensure your digital presence is top-notch, with the latest technology and a personal touch.<br />
            </p>
            <h1>Let’s make your online journey a success together—one step at a time.</h1>
            <a className='btn-filled' href="/vps">Get Started</a>
        </div>

    )
}

export default About