const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer>
            <p>&copy; 2023-{year} ScarceHost</p>
            <div className="tri-links">
                <div className="tri-link">
                    <h4>Company</h4>
                    <a href="/about">About Us</a>
                    <a href="https://billing.scarcehost.co.uk/contact.php">Contact Us</a>
                </div>
                <div className="tri-link">
                    <h4>Support</h4>
                    <a href="https://billing.scarcehost.co.uk/contact.php" target="_blank" rel="noreferrer">Tickets</a>
                    <a href="https://billing.scarcehost.co.uk/knowledgebase" target="_blank" rel="noreferrer">Knowledge Base</a>
                    <a href="https://discord.gg/92Zfh2bf2V">Discord</a>
                </div>
                <div className="tri-link">
                    <h4>Legal</h4>
                    <a href="https://scarcehost.co.uk/tos.html" target="_blank" rel="noreferrer">Terms of Service</a>
                    <a href="https://scarcehost.co.uk/privacy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
                    <a href="https://scarcehost.co.uk/fairuse.html" target="_blank" rel="noreferrer">Fair Usage Policy</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;