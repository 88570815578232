import React, { useState } from "react";
import logo from '../logo.svg';

const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    // Function to toggle the menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div>
            <nav className={"navbar"}>
                <div className={"navbar-container"}>
                    <div className={"logo-container"}>
                        <img className={"navbar-logo"} src={"/combined-color.png"} alt="logo" />
                    </div>
                    <div className="navbar-mobile">
                        <img className={"navbar-logo-mobile"} src={"/logo-color.svg"} alt="logo" />


                        <div className={"menu-icon"} onClick={toggleMenu}>
                            {/* Hamburger icon */}
                            <span className={menuOpen ? "bar open" : "bar"}></span>
                            <span className={menuOpen ? "bar open" : "bar"}></span>
                            <span className={menuOpen ? "bar open" : "bar"}></span>
                        </div>
                    </div>
                </div>

                <div className={menuOpen ? "links active" : "links"}>
                    <a className="nav-link" href="/">
                        Home
                    </a>
                    <a className="nav-link" href="/about">
                        About Us
                    </a>
                    <a className="nav-link" href="https://billing.scarcehost.co.uk/contact.php" target="_black" rel="noreferrer">
                        Contact Us
                    </a>
                    <div className={"dropdown"}>
                        <a className="nav-link" href="#">
                            Support
                        </a>
                        <div className={"dropdown-content"}>
                            <a className="nav-link" href="https://billing.scarcehost.co.uk/contact.php">
                                Tickets
                            </a>
                            <a className="nav-link" href="https://billing.scarcehost.co.uk/knowledgebase">
                                Knowledge Base
                            </a>
                            <a className="nav-link" href="https://discord.gg/92Zfh2bf2V">
                                Discord
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Sub-navbar */}
            <div className={"sub-navbar"}>
                <a className="nav-link" href={"/minecraft"}>
                    Minecraft
                </a>
                <a className="nav-link" href={"/rust"}>
                    Rust
                </a>
                <a className="nav-link" href={"/vps"}>
                    VPS
                </a>
                <a className="nav-link" href={"/website"}>
                    Website
                </a>
                <a className="nav-link" href={"/bot"}>
                    Discord Bot
                </a>
            </div>
        </div>
    );
};

export default Navbar;
